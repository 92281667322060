<template>
  <div>
    <b-modal
        id="modal-component-form"
        size="lg"
        no-close-on-backdrop
        header-class="d-block"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createFormItem.choose_form_template') }}</h3>
      </template>
      <template #default>
        <b-row>
          <b-col cols="12" sm="3" class="border-right-light-grey">
            <ul class="form_templates pl-2 mt-5 pb-5">
              <li class="nav-item">
                <a
                    href="#"
                    :class="{ active: (selectedSharedItem === null) }"
                    class="nav-link"
                    @click="loadEmptyContent()"
                >
                  {{ $t('modals.createFormItem.form.emptyForm') }}
                </a>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" sm="9">
            <div v-if="selectedSharedItem === null" class="m-5 pb-5">
              <h4>{{ $t('modals.createItem.project.emptyProject') }}</h4>
              <p>{{ $t('modals.createItem.project.emptyProjectDesc') }}</p>
              <b-button
                  class="mr-0 ml-0 green-button"
                  @click="showFormNameModal"
              >
                <b-icon
                    icon="plus-circle-fill"
                    class="mr-2"
                    aria-hidden="true"
                    scale="1"
                ></b-icon>
                {{ $t('webmag.select') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            @click="close()"
            block
            class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="modal-add-new-form-item"
        ref="modal-add-new-form-item"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createFormItem.title') }}</h3>
      </template>

      <template #default>
        <modal-choose-team-name-and-description
            :teamName="formItemName"
            :teamDescription="formItemDescription"
            @update-fields="updateFields"
            @enter-pressed="createFormItem"
        ></modal-choose-team-name-and-description>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            @click="close()"
            block
            class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="!isFormItemNameSet"
            variant="primary"
            class="p-3"
            block
            @click="createFormItem"
        >
          {{ $t('modals.createFormItem.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalAddNewFormItem',
  components: {
    ModalChooseTeamNameAndDescription: () => import('@/components/modals/ModalChooseTeamNameAndDescription.vue'),
  },
  data: () => ({
    selectedSharedItem: null,
    previewKey: 1,
    itemName: null,
    formItemName: null,
    formItemDescription: null,
  }),
  computed: {
    isFormItemNameSet() {
      return !!this.formItemName;
    },
  },
  methods: {
    loadEmptyContent() {
      this.selectedSharedItem = null;
      this.previewKey++;
    },
    showFormNameModal() {
      this.$root.$emit('bv::hide::modal', 'modal-component-form');
      this.$root.$emit('bv::show::modal', 'modal-add-new-form-item');
    },
    confirmOkModalByEnterKey() {
      this.handleOk();
    },
    updateFields(key, value) {
      if (key === 'teamName') {
        this.formItemName = value;
      } else {
        this.formItemDescription = value;
      }
    },
    async createFormItem() {
      if (this.isFormItemNameSet) {
        this.$emit('create-form-item', { name: this.formItemName, desc: this.formItemDescription });
        this.$refs['modal-add-new-form-item'].hide();
      }
    },
  },
};
</script>

<style lang="scss">
ul {
  list-style: none;
  font-size: 15px;
}

li a {
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;

  &.active {
    color: #53dc86;
  }
}
.icon {
  position: relative;
  top: 4px;
}
</style>
